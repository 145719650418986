import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
  }

  remove(event, callback = null) {
    event.preventDefault();
    if (event.currentTarget.dataset["removable"] === "true") {
      this.element.remove();
    } else {
      this.inputTarget.value = 1
      this.element.classList.add("d-none");
    }
  }
}
