import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "quantity",
    "price",
    "totalPrice",
    "totalPricePo",
    "hiddenTotalPrice",
    "selectedSku",
    "warningMessage",
  ];

  connect() {
    this.calculateTotalPrice();
    this.checkFormValidity();
  }

  calculateTotalPrice() {
    let quantityTargets = this.quantityTargets;
    let priceTargets = this.priceTargets;
    let totalPriceTargets = this.totalPriceTargets;


    totalPriceTargets.forEach((totalPriceTarget, index) => {
      let quantity = quantityTargets[index].value;
      let price = priceTargets[index].value;


      let totalPrice = quantity * price;

      totalPriceTarget.value = totalPrice.toFixed(2);
    });
    let sumOfTotalPoTargets = this.totalPriceTargets.reduce((sum, el) => sum + Number(el.value), 0);
    this.totalPricePoTarget.value = sumOfTotalPoTargets
    // this.hiddenTotalPriceTarget.value = sumOfTotalPoTargets
    this.checkFormValidity()
  }

  recalculateTotalPricePo(){
    this.calculateTotalPrice()
  }

  checkFormValidity() {
    const emptyQuantity = this.quantityTargets.some((input) => input.value === "");
    const emptyPrice = this.priceTargets.some((input) => input.value === "");
    const emptySkus = this.selectedSkuTargets.some((input) => input.value === "");
    
    const selectedSkus = this.selectedSkuTargets.map((input) => input.value);
    const duplicateSkus = selectedSkus.filter((sku, index) => selectedSkus.indexOf(sku) !== index);

    if (emptyQuantity || emptyPrice || emptySkus || duplicateSkus.length > 0) {
      $(':input[id="po-submit"]').prop("disabled", true);
      this.warningMessageTarget.classList.remove("d-none");
    } else {
      $(':input[id="po-submit"]').prop("disabled", false);
      this.warningMessageTarget.classList.add("d-none");
    }
  }
}
