import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "option" ]

  connect() {
  }

  changeActive(event) {
    let selectedTarget = event.target.options[event.target.options.selectedIndex]
    let unit = selectedTarget.dataset.measurementUnit ? selectedTarget.dataset.measurementUnit : selectedTarget.value
    this.optionTargets.forEach((option) => {
      if (option.dataset.unit === unit) {
        option.classList.remove('d-none')
        option.querySelectorAll('input').forEach(input => input.disabled = false)
        option.querySelectorAll('select').forEach(input => input.disabled = false)
      } else {
        option.classList.add('d-none')
        option.querySelectorAll('input').forEach(input => input.disabled = true)
        option.querySelectorAll('select').forEach(input => input.disabled = true)
        option.querySelectorAll('select').forEach(input => input.value = null)
      }
    })
  }
}
