import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "type",
    "user",
    "supplier",
    "tax",
    "employeecost",
    "operation",
    "value",
    "check",
    "invert",
    "required",
    "multirequired",
    "requireduser",
    "multirequireduser",
    "multidepositamount",
    "multidepositdate",
    "multidepositdifference",
    "date",
    "remove",
    "requiredsupplier",
    "container",
    "template",
  ];

  i = 0; 
  // checkear valor de operacion
  connect() {
    this.i = 0;
    $(':input[id="submit"]').prop("disabled", true);
    $(':input[id="multi-submit"]').prop("disabled", true);
    this.assignMultiDepositDate();
    if (this.operationTarget.value) {
      this.getResult()
    } else {
      this.userTarget.classList.add("d-none");
      this.supplierTarget.classList.add("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.add("d-none");
    }
  }

  getOperation(event) {
    event.preventDefault();

    if (this.operationTarget.value === "check_debit") {
      this.typeTarget.parentElement.classList.add("d-none");
      this.userTarget.classList.add("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.add("d-none");
      this.supplierTarget.classList.add("d-none");
      this.valueTarget.classList.add("d-none");
      this.checkTarget.classList.remove("d-none");

      // hide invert option
      this.invertTarget.classList.add("d-none");
    } else {
      $(".message")[0].classList.add("d-none");
      this.userTarget.classList.add("d-none");
      this.typeTarget.parentElement.classList.remove("d-none");
      this.supplierTarget.classList.remove("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.add("d-none");
      this.invertTarget.classList.remove("d-none");
      this.checkTarget.classList.add("d-none");
      this.typeTarget.classList.remove("d-none");

      this.formLimitations(this.operationTarget.value);

      this.getResult();
    }
  }

  getResult(event = null) {
    if (event) {
      event.preventDefault();
    }

    if (this.typeTarget.value === "supplier") {
      this.userTarget.classList.add("d-none");
      this.supplierTarget.classList.remove("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.add("d-none");
    } else if (this.typeTarget.value === "patient") {
      this.userTarget.classList.remove("d-none");
      this.supplierTarget.classList.add("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.add("d-none");
    } else if (this.typeTarget.value === "taxes") {
      this.userTarget.classList.add("d-none");
      this.supplierTarget.classList.add("d-none");
      this.taxTarget.classList.remove("d-none");
      this.employeecostTarget.classList.add("d-none");
    } else if (this.typeTarget.value === "employee_cost") {
      this.userTarget.classList.add("d-none");
      this.supplierTarget.classList.add("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.remove("d-none");
    } else {
      this.userTarget.classList.add("d-none");
      this.supplierTarget.classList.add("d-none");
      this.taxTarget.classList.add("d-none");
      this.employeecostTarget.classList.add("d-none");
    }
  }

  formLimitations(actor) {
    const $dropdown = $("select[name='bank_transaction[actor_type]']");

    $dropdown.find('option[value="taxes"]').prop("disabled", false);
    $dropdown.find('option[value="wages"]').prop("disabled", false);

    switch (actor) {
      case "debit":
        $dropdown.find('option[value="supplier"]').prop("disabled", false);
        $dropdown.find('option[value="patient"]').prop("disabled", true);
        $dropdown.find('option[value="supplier"]').prop("selected", true);
        $dropdown.find('option[value="taxes"]').prop("disabled", false);
        $dropdown.find('option[value="employee_cost"]').prop("disabled", false);
        this.valueTarget.classList.remove("d-none");

        break;
      case "credit_card":
        $dropdown.find('option[value="supplier"]').prop("disabled", false);
        $dropdown.find('option[value="patient"]').prop("disabled", true);
        $dropdown.find('option[value="supplier"]').prop("selected", true);
        $dropdown.find('option[value="taxes"]').prop("disabled", true);
        $dropdown.find('option[value="employee_cost"]').prop("disabled", true);
        this.valueTarget.classList.remove("d-none");

        break;
      case "deposit":
        $dropdown.find('option[value="supplier"]').prop("disabled", true);
        $dropdown.find('option[value="taxes"]').prop("disabled", true);
        $dropdown.find('option[value="employee_cost"]').prop("disabled", true);
        $dropdown.find('option[value="patient"]').prop("disabled", false);
        $dropdown.find('option[value="patient"]').prop("selected", true);

        break;
      case "transfer":
        $dropdown.find('option[value="supplier"]').prop("disabled", false);
        $dropdown.find('option[value="patient"]').prop("disabled", false);
        $dropdown.find('option[value="taxes"]').prop("disabled", false);
        $dropdown.find('option[value="employee_cost"]').prop("disabled", false);
        break;
    }
  }

  validate() {
    let isRequiredFilled = this.requiredTargets.every((el) => el.value !== "");

    if (this.typeTarget.value === "supplier") {
      isRequiredFilled =
        isRequiredFilled && this.requiredsupplierTarget.value !== "";
    } else if (this.typeTarget.value === "patient") {
      isRequiredFilled =
        isRequiredFilled && this.requireduserTarget.value !== "";
    }
    if (this.operationTarget.value === "check_debit") {
      $(':input[id="submit"]').prop("disabled", true);
    } else {
      this.presentValues(isRequiredFilled);
    }
  }

  presentValues(isRequiredFilled) {
    if (isRequiredFilled) {
      $(':input[id="submit"]').prop("disabled", false);
    } else {
      $(':input[id="submit"]').prop("disabled", true);
    }
  }

  addField(event) {
    event.preventDefault();
    this.i++;
    const content = this.templateTarget.innerHTML;
    const dynamicContent = content.replace(/bank_transaction\[user_id\]/g, `bank_transaction[user_id_${this.i}]`)
                                  .replace(/bank_transaction\[value\]/g, `bank_transaction[value_${this.i}]`)
                                  .replace(/bank_transaction\[operation\]/g, `bank_transaction[operation_${this.i}]`)
                                  .replace(/bank_transaction\[comment\]/g, `bank_transaction[comment_${this.i}]`)
                                  .replace(/bank_transaction\[reference\]/g, `bank_transaction[reference_${this.i}]`);
    this.containerTarget.insertAdjacentHTML('beforeend', dynamicContent)
  }

  
  validateMultiDeposit() {
    let sumOfRequiredTargets = this.multirequiredTargets.reduce((sum, el) => sum + Number(el.value), 0);
    let difference = sumOfRequiredTargets - this.multidepositamountTarget.value;

    if (sumOfRequiredTargets > 0 && sumOfRequiredTargets == this.multidepositamountTarget.value) {
      $(':input[id="multi-submit"]').prop("disabled", false);
      this.multidepositdifferenceTarget.classList.add("d-none")
    } else {
      $(':input[id="multi-submit"]').prop("disabled", true);
      this.multidepositdifferenceTarget.classList.remove("d-none")
    }
  }

  recalculateTotal() {
    this.validateMultiDeposit()
  }

  assignMultiDepositDate() {
    const multiDepositDateValue = this.multidepositdateTarget.value;
    this.dateTargets.forEach((dateTarget) => {
      dateTarget.value = multiDepositDateValue;
    });
  }
}
