import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "measurement", "lastPrice" ]

  connect() {
  }

  showDetails(event) {
    this.measurementTarget.innerHTML = event.target.options[event.target.options.selectedIndex].dataset.measurementFormat
    this.lastPriceTarget.innerHTML = event.target.options[event.target.options.selectedIndex].dataset.lastPrice
  }
}
