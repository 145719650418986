import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["paymentType", "rechargeAmount", "discountAmount", "rechargePercentage", "discountPercentage", "netAmount", "grossAmount", "submitButton"];

  connect() {
    this.paymentRules = JSON.parse(this.element.dataset.paymentRules);
  }

  updateAmounts() {
    const paymentType = this.paymentTypeTarget.value;
    const netAmount = parseFloat(this.netAmountTarget.value.replace(/[^0-9.-]+/g,""));
    const grossAmount = parseFloat(this.grossAmountTarget.value.replace(/[^0-9.-]+/g,""));
    const rules = this.paymentRules[paymentType];

    this.rechargeAmountTarget.value = rules.recharge_amount;
    // this.discountAmountTarget.value = rules.discount_amount;
    this.rechargePercentageTarget.value = rules.recharge_percentage;
    this.discountPercentageTarget.value = rules.discount_percentage;

    
    const rechargeAmountFromPercentage = (grossAmount / 100) * (rules.recharge_percentage);
    const discountAmountFromPercentage = (grossAmount / 100) * (rules.discount_percentage);
    
    const updatedNetAmount = grossAmount + rules.recharge_amount + rechargeAmountFromPercentage - this.discountAmountTarget.value - discountAmountFromPercentage;
    this.netAmountTarget.value = updatedNetAmount.toFixed(2);
    this.validate();
  }

  validate() {
    if (this.discountAmountTarget.value == "") {
      this.discountAmountTarget.value = 0
    }
    const allFieldsCompleted = this.paymentTypeTarget.value &&
                               this.rechargeAmountTarget.value &&
                               this.discountAmountTarget.value &&
                               this.rechargePercentageTarget.value &&
                               this.discountPercentageTarget.value &&
                               this.netAmountTarget.value;

    const discountAmountValid = parseFloat(this.discountAmountTarget.value) <= parseFloat(this.grossAmountTarget.value);

    this.submitButtonTarget.disabled = !(allFieldsCompleted && discountAmountValid);
  }
}