import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["calendarDay", "pasteDayBtn", "pasteWeekBtn", "weekToggleBtn", "holidayTemplate"]

  connect() {
    this.fetchHolidays()
  }

  copyDay(event) {
    event.preventDefault();
    const date = event.currentTarget.dataset.date
    this.pasteDayBtnTargets.forEach((paste_btn) => {
      paste_btn.href = `${paste_btn.dataset.baseUrl}&copy=${date.replace(/['"]+/g, '')}`
    })
  }

  copyWeek(event) {
    event.preventDefault();
    const week = event.currentTarget.dataset.week
    this.pasteWeekBtnTargets.forEach((paste_btn) => {
      paste_btn.href = `${paste_btn.dataset.baseUrl}&copy=${week.replace(/['"]+/g, '')}`
    })
  }

  addDaySpinner(event) {
    event.currentTarget.closest("td.day-menus").innerHTML = `
      <div class="container-fluid h-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      `
  }

  addWeekSpinners() {
    this.weekToggleBtnTargets.forEach((btn) => {
      btn.innerHTML = `
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      `
    })
  }

  fetchHolidays() {
    const month = this.element.dataset.month
    const holidayUrl = `http://nolaborables.com.ar/api/v2/feriados/2020?formato=mensual&incluir=opcional`
    fetch(holidayUrl)
      .then(response => response.json())
      .then(data => {
        const dataForMonth = Object.entries(data[month - 1])
        const holidayTemplate = this.holidayTemplateTarget
        dataForMonth.forEach((day) => {
          const date = day[0]
          let holidays = day[1]
          if (!Array.isArray(holidays)) {
            holidays = [holidays]
          }
          holidays.forEach((dayInfo) => {
            const content = holidayTemplate.innerHTML.replace(/TEMPLATE_ID/g, new Date().valueOf()).replace(/MOTIVO/g, dayInfo["motivo"]);
            const selectedCalendarDay = this.calendarDayTargets.filter((calendarDay) => { 
              return (calendarDay.dataset.day == date && calendarDay.dataset.month == month)
            })
            selectedCalendarDay[0].insertAdjacentHTML('beforeend', content)
          })
        })
      })
  }

}
