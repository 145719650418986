import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "mealtimeInput", "deliveryDateInput", "typeOfDietInput"]

  connect() {
  }

  newMenuHandleClick(event) {
    event.preventDefault();
    this.mealtimeInputTarget.value = event.currentTarget.dataset.mealtime
    this.deliveryDateInputTarget.value = event.currentTarget.dataset.deliveryDate
    this.typeOfDietInputTarget.value = event.currentTarget.dataset.typeOfDiet
    if (["Media Tarde", "Merienda", "Cena"].includes(event.currentTarget.dataset.mealtime)) {
      this.containerTarget.classList.add("left")
    } else {
      this.containerTarget.classList.add("right")
    }
    this.containerTarget.classList.add("show")
  }

  closeForm(event) {
    event.preventDefault();
    this.containerTarget.classList.remove("show")
    setTimeout(() => {
      this.containerTarget.classList.remove("left", "center", "right")
    }, 500)
  }

  alignDock(event) {
    this.containerTarget.dataset.newDock = event.currentTarget.dataset.align
    this.containerTarget.classList.remove("show")
    setTimeout(() => {
      this.containerTarget.classList.remove("left", "center", "right")
    }, 300)
    setTimeout(() => {
      this.containerTarget.classList.add(this.containerTarget.dataset.newDock)
    }, 310)
    setTimeout(() => {
      this.containerTarget.classList.add("show")
    }, 320)
  }
}
