import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pasteBtn", "pasteDietBtn"]

  connect() {
  }

  copySingle(event) {
    event.preventDefault();
    this.pasteBtnTargets.forEach((paste_btn) => {
      paste_btn.classList.remove("disabled")
      paste_btn.href = `${paste_btn.dataset.baseUrl}&copy=${event.target.dataset.menu}`
    })
  }

  copyDiet(event) {
    event.preventDefault();
    this.pasteDietBtnTargets.forEach((paste_btn) => {
      paste_btn.href = `${paste_btn.dataset.baseUrl}&diet=${paste_btn.dataset.diet}&copy=${event.target.dataset.diet}`
    })
  }
}
