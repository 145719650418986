import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  pickDate(event) {
    const queryAttr = this.data.get("queryattr")
    const url = `${window.location.pathname}?${queryAttr}=${event.target.value}`
    window.location.replace(url)
  }
}
