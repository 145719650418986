import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "required",
    "price"
  ];

  connect() {
    $(':input[id="price_submit"]').prop("disabled", false);
  }
  
  validateSkuPrice() {
    const priceValue = parseFloat(this.priceTarget.value);
    if (isNaN(priceValue) || priceValue < 0) {
      $(':input[id="price_submit"]').prop("disabled", true);
    } else {
      $(':input[id="price_submit"]').prop("disabled", false);
    }
  }
}
