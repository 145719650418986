import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "address", "zipCode", "city", "province", "floor" ]

  connect() {
    let autocomplete = new google.maps.places.Autocomplete(this.addressTarget, { types: [ 'geocode' ], componentRestrictions: {country: 'arg'} });
    google.maps.event.addDomListener(this.addressTarget, 'keydown', function(e) {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    });

    const addressInput = this.addressTarget
    let autocompleteAddress = new google.maps.places.Autocomplete(addressInput, {
      componentRestrictions: { country: ["arg"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    addressInput.focus();
    autocompleteAddress.addListener("place_changed", () => {
      const place = autocompleteAddress.getPlace();
      let postcode = "";

      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case "locality":
            this.cityTarget.value = component.long_name;
            break;
          case "administrative_area_level_1": {
            this.provinceTarget.value = component.short_name;
            break;
          }
        }
      }
      this.zipCodeTarget.value = postcode;
      this.floorTarget.focus();
    });
  }
}
