import Rails from "@rails/ujs"
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let meals_ids = event.item.dataset.meals
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    data.append("ids", id)
    data.append("meals_ids", meals_ids)

    Rails.ajax({
      url: this.data.get("url"),
      type: 'PATCH',
      data: data
    })
  }
}