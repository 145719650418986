import flatpickr from "flatpickr";
import { Controller } from "stimulus"
import { Spanish } from "flatpickr/dist/l10n/es.js"

export default class extends Controller {
  connect() {
    const date = flatpickr(this.element, {
      "locale": Spanish,
      "minDate": "today",

    })
  }
}
