import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "container" ]

  connect() {
  }

  addField(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_ID/g, new Date().valueOf());
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }
}
