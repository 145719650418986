import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "container", "button", "input", "page" ]

  connect() {
  }

  addField(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML
    this.containerTarget.insertAdjacentHTML('beforeend', content)
    this.buttonTarget.classList.add('d-none')
  }

  removeField(event) {
    event.preventDefault();
    this.pageTarget.remove()
    this.buttonTarget.classList.remove('d-none')
  }
}
