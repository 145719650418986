import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["paste_btn"]

  connect() {
  }

  copy(event) {
    event.preventDefault();
    this.paste_btnTargets.forEach((paste_btn) => {
      paste_btn.href = `${paste_btn.dataset.baseUrl}?copy=${event.target.dataset.index}&paste=${paste_btn.dataset.index}`
    })
  }
  
  submit(event) {
    Rails.fire(this.element, 'submit')
  }
}
