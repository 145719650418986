import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  clickLink(event) {
    event.currentTarget.firstElementChild.click()
  }

}
