import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "remove", "pairs", "submit", "form", "invoice"];

  connect() {
  }

  isNumeric(value) {
    return /^-?\d+(\.\d+)?$/.test(value);
  }

  validateValuesToAddUp() {
    // enable button
    // disable button
    return true
  }

  isValid() {
    const paymentsList = document.querySelector("#payment-list")
    const invoiceIdValuePairs = paymentsList.querySelectorAll(".invoice_payments_group");
    invoiceIdValuePairs.forEach((pair) => {
      const transaction = pair.querySelector(".bank-transaction").value;
      const value = pair.querySelector(".bank-transaction-value").value;
      if (this.isNumeric(transaction) && this.isNumeric(value) && this.isNumeric(this.invoiceTarget.value)) {
        this.submitTarget.removeAttribute("disabled", "")
      } else {
        this.submitTarget.setAttribute("disabled", "")
      }
    });
  }

  createInvoiceIdValuePair() {
    const paymentsList = document.querySelector("#payment-list")
    const invoiceIdValuePairs = paymentsList.querySelectorAll(".invoice_payments_group");
    let idValueList = "";
    let invoiceId = 0;
    let value = 0;
    invoiceIdValuePairs.forEach((pair) => {
      invoiceId = pair.querySelector(".bank-transaction").value;
      idValueList += `${invoiceId},`;
      value = pair.querySelector(".bank-transaction-value").value;
      idValueList += `${value},`;
    });
    this.pairsTarget.value = idValueList;
  }

  submit(event) {
    event.preventDefault();
    if (this.validateValuesToAddUp()) {
      this.createInvoiceIdValuePair();
      this.formTarget.submit();
    }
  }
}
