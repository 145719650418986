import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.allCheckbox = this.element.querySelector('input[data-all-mealtime]')
    this.mealtimeTargets = this.element.querySelectorAll(".form-check-input")
  }
  
  toggleAll() {
    this.mealtimeTargets.forEach((mealtime) => {
      mealtime.checked = this.allCheckbox.checked
    })
  }

  updateAllCheckbox() {
    const allChecked = Array.from(this.mealtimeTargets).every((mealtime) => mealtime.checked)
    const someUnchecked = Array.from(this.mealtimeTargets).some((mealtime) => !mealtime.checked)
    this.allCheckbox.checked = allChecked
    this.allCheckbox.indeterminate = !allChecked && !someUnchecked
  }
}