import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["date", "links", "dateFrom", "amountOfDays"]

  pickDate(e) {
    let dates = e.target.dataset.value
    let links = this.linksTarget.children
    for (let link of links) {
      const link_without_date = link.href.split('&date')[0]
      link.href = `${link_without_date}&dates=${dates}`
    }
  }

  updateDateRange() {
    const dateFrom = this.dateFromTarget.value;
    const amountOfDays = this.amountOfDaysTarget.value;
    const dateTo = new Date(dateFrom);
    dateTo.setDate(dateTo.getDate() + parseInt(amountOfDays));

    const dateRangeDiv = document.getElementById('deliveryPlacementBtn');
    dateRangeDiv.setAttribute('data-value', `${dateFrom},${dateTo.toISOString().split('T')[0]}`);
  }
}
