import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["orderSelect", "netAmount"];

  connect() {
  }

  calculateNetAmount() {
    setTimeout(() => {
      let selectedOrders = document.querySelectorAll(".ss-value");
      let totalPrice = 0
      selectedOrders.forEach((order) => {
        totalPrice += parseInt(document.getElementById(order.dataset["id"]).getAttribute('data-order-price'));
      });
      this.netAmountTarget.innerHTML = totalPrice.toFixed(2);
    }, 300);
  }
}