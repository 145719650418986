import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["name", "message"];

  connect() {}

  getResult(event) {
    event.preventDefault();
    const query = this.nameTarget.value;
    const resources = this.element.dataset.resources;
    const warningMessage = this.element.dataset.warningMessage;
    if (query.length > 3) {
      fetch(`/searches/${resources}/${query}`)
        .then((response) => response.json())
        .then((data) => {
          if (data[`${resources}`].length > 0) {
            this.messageTarget.classList.remove("d-none");
            this.messageTarget.innerText = `${warningMessage} ${data[
              `${resources}`
            ]
              .map((element) => `'${element.name}'`)
              .join(" - ")}`;
          } else {
            this.messageTarget.classList.add("d-none");
          }
        });
    } else {
      this.messageTarget.classList.add("d-none");
    }
  }

  getCheckResult(event) {
    event.preventDefault();
    const query = this.nameTarget.value;
    const resources = this.element.dataset.resources;
    const warningMessage = this.element.dataset.warningMessage;
    fetch(`/searches/${resources}/${query}`)
      .then((response) => response.json())
      .then((data) => {
        if (data[`${resources}`].length > 0) {
          this.messageTarget.classList.remove("d-none");
          this.messageTarget.innerText = `${data[`${resources}`][1]}`;
          this.messageTarget.classList.add(`text-${data[`${resources}`][0]}`);

          if (`${data[`${resources}`][0]}` === "danger") {
            $(':input[type="submit"]').prop("disabled", true);
          } else {
            this.messageTarget.classList.remove("text-danger");
            $(':input[type="submit"]').prop("disabled", false);
          }
        } else {
          this.messageTarget.classList.add("d-none");
        }
      });
  }
}
